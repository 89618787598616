<template>
  <div class="w-100">
    <div class="w-100">
      <resume :period="filter.period" @loadRangeTime="setRangeTime" ref="resume"/>
    </div>
    <b-card>
      <b-row class="mb-2">
        <b-col>
          <label>Is completed?</label>
          <select class="form-control" v-model="filter.is_completed" @change="load">
            <option :value="true">Yes</option>
            <option :value="false">No</option>
          </select>
        </b-col>
        <b-col>
          <label>Has error?</label>
          <select class="form-control" v-model="filter.reported_error" @change="load">
            <option :value="true">Yes</option>
            <option :value="false">No</option>
          </select>
        </b-col>
        <b-col>
          <label>Has certificate</label>
          <select class="form-control" v-model="filter.certification" @change="load">
            <option :value="true">Yes</option>
            <option :value="false">No</option>
          </select>
        </b-col>
        <b-col>
          <label>Period: <small
            class="font-weight-light">{{ rangeTime.start | localDateShort }} - {{
              rangeTime.end | localDateShort
            }}</small>
          </label>
          <select class="form-control" v-model="filter.period">
            <option value="week">THIS WEEK</option>
            <option value="month">THIS MONTH</option>
            <option value="year">THIS YEAR</option>
          </select>
        </b-col>
        <b-col>
          <label for="">Type to search by VIN</label>
          <input type="text" class="form-control" placeholder="Search by VIN" v-model="filter.query"
                 @keyup="keySearch($event.target.value)">
        </b-col>
        <b-col>
          <label>Deleted</label>
          <select class="form-control" v-model="filter.deleted" @change="load">
            <option :value="null">All</option>
            <option :value="true">Yes</option>
            <option :value="false">No</option>
          </select>
        </b-col>
        <b-col lg="1" class="d-flex justify-content-center align-items-end">
          <b-button class="mt-4" variant="primary" @click="clearFilter">Show default</b-button>
        </b-col>
        <b-col lg="1" class="d-flex justify-content-center align-items-end">
          <router-link class="btn btn-primary" :to="{name: 'file-pre-order-crypt-bot-monitor'}" v-slot="{ navigate }">
            <span @click="navigate" @keypress.enter="navigate" role="link">CMD pre-order</span>
          </router-link>
        </b-col>
      </b-row>
      <div class="table-responsive">
        <b-table small hover class="dataTable" bordered :items="arrayData" :fields="fields">
          <template #cell(order_id)="{item}">
            <router-link style="text-decoration: underline; cursor:pointer;"
                         :to="{name: 'order-detail', params: {id: changeUrl(item.order_id)}}"
                         target="_blank">
              <span class="font-weight-bold">{{ item.order ? item.order.id : item.order_id }}</span>
            </router-link>
          </template>
          <template #cell(is_completed)="{item}">
                <span :class="item.is_completed? 'text-success': 'text-danger'">{{
                    item.is_completed ? "Yes" : "No"
                  }}</span>
          </template>
          <template #cell(created_at)="{item}">
            {{ item.created_at | localeDate }}
          </template>
          <template #cell(tuned_file_object)="{item}">
            <router-link style="text-decoration: underline; cursor:pointer;"
                         :to="{name: 'view-tuned-file', params: {id: item.tuned_file_id}}"
                         target="_blank">
              <span class="font-weight-bold">{{ item.tuned_file_object.title }}</span>
            </router-link>
          </template>
          <template #cell(reported_error)="{item}">
            <span v-if="!item.reported_error">No</span>
            <span v-else
                  class="font-weight-bold text-danger"
                  v-tippy="{ placement : 'top',  arrow: true }"
                  :content="item.reported_error">Yes<i class="material-icons ml-2">info</i></span>
          </template>
          <template #cell(certification)="{item}">
            <template v-if="typeof item.certification === 'object'">
              <template v-if="item.certification.passed || item.certification.req_manual">
                <span class="font-weight-bold" :class="item.certification.passed? '':'text-danger'">{{
                    item.certification.passed ? 'Yes' : 'No'
                  }}
                </span>
                <span class="font-weight-bold text-danger"
                      v-if="item.certification.result && item.certification.result.length"
                      :name="item.certification._id">
                  <i class="material-icons ml-2">info</i>
                </span>
                <tippy v-if="item.certification.result && item.certification.result.length" :to="item.certification._id"
                       class="hidden" v-tippy-html>
                  <div style="white-space: break-spaces">
                    {{ item.certification.result.join('\r\n') }}
                  </div>
                </tippy>
              </template>
              <template v-else>
                <div>Pending</div>
              </template>
            </template>
            <span v-else> -- </span>
          </template>

          <template #cell(actions)="{item}">
            <div v-if="showButtons('processing', item)"
                 v-tippy="{ placement : 'top',  arrow: true }"
                 content="Processing"
                 class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <b-button-group v-else-if="showButtons('isCompleted', item)">
              <b-button variant="success" size="sm"
                        v-if="showButtons('encrypt', item)"
                        @click="sendToEncrypt(item)"
                        v-tippy="{ placement : 'top',  arrow: true }"
                        content="Try encrypt">
                <i class="material-icons">refresh</i>
              </b-button>
              <b-button v-if="showButtons('certificate_manual', item)" @click="manualCertificate(item)"
                        variant="info" size="sm" v-tippy="{ placement : 'top',  arrow: true }"
                        content="Could not be possible auto-certification, this action is required to continue.">
                <i class="material-icons">touch_app</i>
              </b-button>
              <b-button v-if="showButtons('certificate', item)" @click="sendToCertificate(item)" variant="warning"
                        size="sm" v-tippy="{ placement : 'top',  arrow: true }"
                        content="Try auto-certificate">
                <i class="material-icons">key</i>
              </b-button>
              <b-button v-if="showButtons('leave', item)" @click="leaveProject(item)" variant="danger" size="sm"
                        v-tippy="{ placement : 'top',  arrow: true }"
                        content="Leave encrypt, System will not try to crypt this file again.">
                <i class="material-icons">close</i>
              </b-button>
            </b-button-group>
            <div v-else>
              {{ item.deleted_at | localeDate }}
            </div>
          </template>

        </b-table>
        <div class="d-flex justify-content-end">
          <b-pagination
            class="justify-content-end no-shadow p-2"
            :value="pagination.currentPage"
            :per-page="pagination.perPage"
            :total-rows="pagination.rows"
            @change="paginate"
          />
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {get, post} from "@/services/api";
import {encrypt} from "@/helpers/encrypt";
import Resume from "./Resume.vue";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "FilesOrder",
  components: {
    Resume
  },
  mixins: [show_alert_mixin],
  data: () => ({
    pagination: {
      currentPage: 1,
      perPage: 20,
      rows: 0
    },
    arrayData: [],
    filter: {
      is_completed: null,
      reported_error: null,
      certification: null,
      query: null,
      period: 'week',
      timer: null,
      deleted: false
    },
    fields: [
      {key: 'tuned_file_object', label: "Name", sortable: true},
      {key: 'order_id', label: "Order Id", sortable: true},
      {key: 'VIN', label: "VIN", sortable: true},
      {key: 'is_completed', label: "Is completed?", sortable: true},
      {key: 'created_at', label: 'Ordered at', sortable: true},
      {key: 'reported_error', label: "Error?", sortable: true},
      {label: 'Cert. process', key: 'certification', sortable: true},
      'actions'
    ],
    rangeTime: {
      start: null,
      end: null
    }
  }),
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.arrayData = []
      let url = `bot-monitor/order-files?page=${this.pagination.currentPage}&limit=${this.pagination.perPage}`
      Object.keys(this.filter).forEach(el => {
        if (this.filter[el] !== null) {
          url += `&${el}=${this.filter[el]}`
        }
      })
      get(url, null, true)
        .then(({data}) => {
          this.arrayData = data.data
          this.pagination.rows = data.count
        })
    },
    clearFilter() {
      this.filter = {
        deleted: null,
        is_completed: null,
        reported_error: null,
        certification: null,
        query: null,
        period: 'week',
        timer: null
      }
      this.rangeTime = {
        start: null,
        end: null
      }
      this.load()
    },
    paginate(page) {
      this.pagination.currentPage = page;
      this.load()
    },
    changeUrl(id) {
      return encrypt(JSON.stringify({id: id}))
    },
    showButtons(action, item) {
      if (item.deleted_at) return false
      const validations = {
        encrypt: function (args) {
          if (!args.certification) return !args.is_completed && !args.is_processing
          return (typeof args.certification === 'object' && args.certification.passed) && (!args.is_completed && !args.processing)
        },
        leave: (args) => {
          return !args.is_completed
        },
        isCompleted: function (args) {
          return args.is_completed === false
        },
        certificate: function (args) {
          return typeof args.certification === 'object' && !args.certification.passed && !args.certification.req_manual
        },
        certificate_manual: function (args) {
          return typeof args.certification === 'object' && !args.certification.passed
        },
        processing: function (args) {
          return args.processing
        }
      }
      return validations[action](item)
    },
    async sendToEncrypt(item) {
      this.showAlertNotification("File sent to encrypt")
      await post(`file-processor/send-to-encrypt/${item._id}`, null, true)
      this.load()
    },
    async sendToCertificate(item) {
      this.showAlertNotification("File sent to certificate")
      await post(`file-processor/send-to-certification/${item._id}`, null, true)
      this.load()
    },
    async leaveProject(item) {
      this.showAlertNotification("Project was leaved correctly")
      await post(`file-processor/leave-project/${item._id}`, null, true)
      this.load()
      this.$refs.resume.getData();
    },
    async manualCertificate(item) {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        title: 'Are you sure you want to confirm this has been certified manually, the system will proceed to encrypt the file tuned?',
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async res => {
        if (res.isConfirmed) {
          this.showAlertNotification("Process successfully")
          await post(`file-processor/manual-certificate/${item._id}`, null, true)
          this.load()
        }
      });
    },
    keySearch(e) {
      this.pagination.currentPage = 1;
      if (e.which == 13) {
        clearTimeout(this.filter.query)
        this.load()
        return
      }
      if (this.filter.timer) {
        clearTimeout(this.filter.timer);
        this.filter.timer = null;
      }
      this.filter.timer = setTimeout(() => {
        this.load()
      }, 500);
    },
    setRangeTime(e) {
      this.rangeTime.start = new Date(e.start).toISOString().slice(0, 10)
      this.rangeTime.end = new Date(e.end).toISOString().slice(0, 10)
    }
  },
}
</script>

<style scoped lang="scss">
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 20px;
}

.lds-ellipsis div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #000000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

</style>
