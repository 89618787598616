<template>
  <b-row class="justify-content-center">
    <b-col v-for="(stats, idx) in smallStats" :key="idx" md="6" lg="3" class="mb-2">
      <small-stats ref="stats" :id="`small-stats-${idx}`"
                   :chart-data="stats.datasets"
                   :label="stats.label"
                   :value="stats.value"
                   :color="stats.color"
                   :percentage="stats.percentage"
                   :increase="stats.increase"
                   :decrease="stats.decrease"
                   variation="1"/>
    </b-col>
  </b-row>
</template>

<script>
import colors from "@/utils/colors";
import SmallStats from "@/components/Shards/SmallStats.vue";
import {get} from "@/services/api";
export default {
  name: "Resume",
  components: {
    SmallStats
  },
  computed: {

  },
  props: {
    period: {
      type: String,
      default: 'week'
    }
  },
  data: () => ({
    smallStats: [
      {
        label: 'SUCCESSFULLY COMPLETED TODAY',
        key: 'completed_today',
        color: '#17c671',
        value: 0,
        percentage: '0%',
        increase: false,
        decrease: true,
        datasets: [{
          label: '',
          color: '#17c671',
          fill: 'start',
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [] // [9, 3, 3, 9, 9],
        }],
      },
      {
        label: 'SUCCESSFULLY COMPLETED PERIOD',
        key: 'completed_period',
        color: '#007bff',
        value: 0,
        percentage: '0%',
        increase: true,
        decrease: false,
        datasets: [{
          label: '',
          color: '#007bff',
          fill: 'start',
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [] // [9, 3, 3, 9, 9],
        }],
      },
      {
        label: 'FAILED TODAY',
        key: 'failed_today',
        color: '#ffb400',
        value: 0,
        percentage: '0%',
        increase: false,
        decrease: true,
        datasets: [{
          label: '',
          color: '#ffb400',
          fill: 'start',
          borderWidth: 1.5,
          backgroundColor: colors.salmon.toRGBA(0.1),
          borderColor: colors.salmon.toRGBA(),
          data: []
        }],
      },
      {
        label: 'FAILED PERIOD',
        key: 'failed_period',
        color: '#ff4169',
        value: 0,
        percentage: '0%',
        increase: true,
        decrease: false,
        datasets: [{
          label: '',
          color: '#ff4169',
          fill: 'start',
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [] // [9, 3, 3, 9, 9],
        }],
      }]
  }),
  mounted() {
    this.getData()
  },
  methods: {
    async getData(){
      const {data} = await get(`bot-monitor/resume?period=${this.period}`, null, true)
      this.$emit("loadRangeTime", {start: data.range_start, end: data.range_end})
      this.smallStats.forEach(el=> {
        Object.keys(data).forEach(val => {
          if(el.key === val){
            el.value = data[val].length
          }
        })
      })
      this.$nextTick(()=> {
          this.$refs.stats
      })
    }
  },
  watch: {
    period: {
      deep: false,
      handler: function (e){
        this.period = e
        this.getData()
      }
    }
  }
}
</script>

<style scoped>

</style>
